<template>
  <div class="tab-pane-inner">
    <div class="inspections-list form-inspection">
      <div class="popup-table-header">
        <h3>{{ $t("COMMON.INSPECTIONS") }}</h3>
        <base-button
          class="add"
          icon
          size="sm"
          @click="addInspection"
          v-if="$currentUserCan($permissions.PERM_CREATE_INSPECTIONS)"
        >
          <span class="btn-inner--icon">
            <i class="fa-regular fa-circle-plus"></i>
          </span>
          <span class="btn-inner--text">
            {{ $t("INSPECTIONS.ADD_INSPECTION") }}
          </span>
        </base-button>
      </div>
      <inspection-list-table
        :add-section="false"
        :columns="tableColumns"
        :filterVehicle="vehicle.id"
        :show-pagination="false"
        @onAddInspection="addInspection"
        @onDeleteInspection="viewInspection"
        @onEditInspection="editInspection"
        @onViewInspection="viewInspection"
        show-all
      />
    </div>
  </div>
</template>

<script>
import {
  QUERY_ACTIONS_ADD,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_VIEW,
} from "@/constants/common";
import InspectionListTable from "@/views/Pages/InspectproModule/InspectionManagement/partials/InspectionListTable.vue";
import CopyElement from "@/components/CopyElement.vue";
import VehicleRefStandardized from "@/components/VehicleRefStandardized.vue";

export default {
  name: "vehicle-view-inspections",

  components: { InspectionListTable, CopyElement, VehicleRefStandardized },

  props: ["vehicle"],

  data() {
    return {
      tableColumns: [
        // "vehicle",
        "vin",
        "customer",
        "model",
        "status",
        // "mechanic",
        "picture_count",
        // "official_mechanic",
        "updated_at",
      ],
    };
  },

  computed: {},

  created() {},

  methods: {
    viewInspection(inspection) {
      this.$router.push({
        name: "List Inspections",
        query: { id: inspection.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    editInspection(inspection) {
      this.$router.push({
        name: "List Inspections",
        query: { id: inspection.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    addInspection() {
      this.$router.push({
        name: "List Inspections",
        query: {
          action: QUERY_ACTIONS_ADD,
          vehicle_id: this.vehicle.id,
        },
      });
    },
  },

  mounted() {},

  watch: {
    vehicle(vehicle) {},
  },
};
</script>
